import React, {Component} from 'react';
import {specificApiConfig} from '../config';
import dayjs from 'dayjs'

export const UserContext = React.createContext();

class UserContextProvider extends Component {
    constructor(props) {
        super(props)
        this.state = {
            isLoggedIn: false,
            userEmail: '',
            accessCode: '',
            originalAccessCode: '',
            testTimer: null,
            deadline: '',
            deadlineDate: '',
            currentStep: '',
            currentTestStepNumber: null,
            clientUrlRoot: '',
            apiUrlRoot: '',
            leapFrogDashboardUrl : '',
            testUnderway: '',
            isLeapFrogTest: '',
            isSampleTest: '',
            sampleTestDeadline: '',
            sampleTestDeadlineDate: '',
            showTimeOutModal: false,
            testTimedOut: false,
            testContact: null,
            testFacilityName: null,
            testType: null,
            leapFrogHospitalId: null,
            ttGroup: null,
            ttYear: null
        }
    }

    getApiConfig = ( apiName ) => {
        return this.state.apiUrlRoot + specificApiConfig[ apiName ];
    }

    loginUser = (user) => {
        this.setState({
            isLoggedIn: true,
            userEmail: user.email,
            accessCode: user.code,
        })
    }
    
    logoutUser = () => {
        this.setState({
            isLoggedIn: false,
            userEmail: '',
            accessCode: '',
            testTimer: 0,
            sessionTimer: 0,
            currentStep: '',
            previousStep: ''
        })
    }

    setCurrentStep = (step) => {
        this.setState({
            currentStep: step
        })
    }
    
    // This function is only called in the dashboard and in the useSetCurrentStep hook
    setStepNumber = (num) => {
        // console.log('Setting step to ', num);
        this.setState({
            currentTestStepNumber: num
        })
        if (num > 0 && num < 7) {
            this.setState({testUnderway: true})
            if (!this.state.isSampleTest) {
                this.checkAndSetStartTime(num) 
            } // with the hook, we don't need to wait to check the time
        } else {
            this.setState({testUnderway: false})
        }
    }

    checkAndSetStartTime = (step) => {
        this.dayJSPrep()
        // console.log('fetching timeout status...');
        fetch(this.getApiConfig('getClockTimeoutStatus'),{
            method: "POST",
            mode: "cors",
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json"
            },
            body: JSON.stringify({
                "access-code": this.state.accessCode,
            })
            })
            .then((response) => response.json())
            .then(data => {
                this.dayJSPrep()
                // console.log('clock status fetched:', data);
                let uTCOffsetPatient = dayjs(data['patient-section-start']).utc('z').local().format('M/D/YYYY h:mm:ss A')
                let uTCOffsetOrders = dayjs(data['orders-and-responses-start']).utc('z').local().format('M/D/YYYY h:mm:ss A')
                if (step <= 2) {this.timerStart(Date.parse(uTCOffsetPatient))}
                else {this.timerStart(Date.parse(uTCOffsetOrders))}
            })
            .catch(err => console.log('error checking timeout status', err))
    }

    dayJSPrep = () => {
        var utc = require('dayjs/plugin/utc')
        var timezone = require('dayjs/plugin/timezone')
        var customParseFormat = require('dayjs/plugin/customparseformat')
        dayjs.extend(utc)
        dayjs.extend(customParseFormat)
        dayjs.extend(timezone)
    }

    calculateDeadlineDate = (time) => {
        this.dayJSPrep()
        let expirationString = new Date(time)
        let deadlineDate = dayjs(expirationString).tz("America/New_York").format('MM/DD/YYYY [at] hh:mm A')
        return deadlineDate
    }

    timerStart = (sectionStart) => {
        this.dayJSPrep()
        let threeHours = 3 * 60 * 60 * 1000 
        // let threeHours = 1 * 60 * 1000 // for testing timeouts, dropping this to one minute
        let bonusFiveSeconds = 5 * 1000
        let testTimeLeft = sectionStart + threeHours + bonusFiveSeconds
        let deadlineDateString = this.calculateDeadlineDate(testTimeLeft);
        let deadlineString = deadlineDateString.substring(deadlineDateString.indexOf('t') + 2)
        this.setState({
            testTimer: testTimeLeft - Date.now(), // store remaining time
            deadline: deadlineString,
            deadlineDate: deadlineDateString
        })
    }


    setSampleTest = () => {
        // set originalAccessCode to accessCode
        // prepend "LFSAMPLE" to access code
        // put user into normal workflow 
        console.log('Setting Sample Test...');
        this.setState({
            originalAccessCode: this.state.accessCode,
            accessCode: "LFSAMPLE" + this.state.accessCode,
            isSampleTest: true
        })
    }

    setSampleTestDeadline = () => {
        this.dayJSPrep()
        // create a value for three hours from now
        let threeHoursFromNow = Date.now() + (3 * 60 * 60 * 1000)
        // convert to Eastern Time
        let sampleDeadlineEST = dayjs(threeHoursFromNow).tz("America/New_York").format('hh:mm A')
        let sampleDeadlineDateEST = dayjs(threeHoursFromNow).tz("America/New_York").format('MM/DD/YYYY [at] hh:mm A')
        // set in state
        console.log('Setting Sample Test Deadline to ', sampleDeadlineEST)
        this.setState({
            sampleTestDeadline: sampleDeadlineEST,
            sampleTestDeadlineDate: sampleDeadlineDateEST
        })
    }

    removeSampleTestDeadline = () => {
        console.log('Clearing sampleTestDeadline...');
        this.setState({ 
            sampleTestDeadline: '', 
            sampleTestDeadlineDate: '' 
        })
    }

    exitSampleTest = () => {
        // Business rule: User is only allowed to exit a sample test when sample test is completed
        console.log('Exiting Sample Test...');
        this.setState({
            accessCode: this.state.originalAccessCode,
            isSampleTest: false,
            currentStep: ''
        })
    }

    setLeapFrogTest = () => {
        // console.log('...LeapFrog Test Detected');
        this.setState({
            isLeapFrogTest: true
        })
    }

    //  TODO: check to see if this can be removed...
    showTheTimeOutModal = () => {
        console.log('Test has timed out, showing the timeout message...');
        this.setState({
            showTimeOutModal: true
            // trigger log out here?
        }
        // , () => {this.logoutUser()}
        )
    }

    setTestTimedOut = () => {
        console.log('timed out, user should be redirected to dashboard and shown the timeout card');
        // if the user times out, set the current step to '/viewresults', stepnumber to 7, set testUnderway to false
        if (this.state.currentTestStepNumber === 5) { // clear localStorage in case test is reset
            window.localStorage.clear()
        }
        this.setState({
            testTimedOut: true,
            currentStep: '/viewresults',
            currentTestStepNumber: 7,
            testUnderway: false
        })
    }


    setContactAndFacilityName = (d) => {
        this.setState({
            testContact: d['hospital-contact-name'],
            testFacilityName: d['hospital-name'],
            testType: d['tt-type'],
            leapFrogHospitalId: d['leapfrog-hospital-id'],
            ttGroup: d['tt-group'],
            ttYear: d['tt-year']
        })
    }

    async componentDidMount() {
        // setting state in context of configuration received from the server 
        fetch(`/config`)
        .then(response => response.json())
        .then(data => {
            this.setState({
            clientUrlRoot: data.ednClientUrlRoot,
            apiUrlRoot: data.ednApiUrlRoot,
            leapFrogDashboardUrl: data.ednLeapfrogDashboard,
            serverDisplayName: data.ednServerType,
        },
        function() {
            console.log("site client server config for clientUrlRoot:" + this.state.clientUrlRoot + "; apiUrlRoot:" + this.state.apiUrlRoot + "; serverDisplayName:" + this.state.serverDisplayName)
            console.log("server config LF Dashboard Url: ", this.state.leapFrogDashboardUrl);
        })});
    }


    render() {
        const { children } = this.props;
        return (
          <UserContext.Provider
            value={{
                // methods and state
                loginUser: this.loginUser,
                logoutUser: this.logoutUser,
                setCurrentStep: this.setCurrentStep,
                setStepNumber: this.setStepNumber,
                getApiConfig: this.getApiConfig,
                setSampleTest: this.setSampleTest,
                exitSampleTest: this.exitSampleTest,
                setLeapFrogTest: this.setLeapFrogTest,
                showTheTimeOutModal: this.showTheTimeOutModal,
                setTestTimedOut: this.setTestTimedOut,
                setContactAndFacilityName: this.setContactAndFacilityName,
                setSampleTestDeadline: this.setSampleTestDeadline,
                removeSampleTestDeadline: this.removeSampleTestDeadline,
                // timerStart: this.timerStart,
                // updateTimeLeft: this.updateTimeLeft,
                // setUserInContext: this.setUserInContext,
                isLoggedIn: this.state.isLoggedIn,
                isLeapFrogTest: this.state.isLeapFrogTest,
                isSampleTest: this.state.isSampleTest,
                currentStep: this.state.currentStep,
                testUnderway: this.state.testUnderway,
                currentTestStepNumber: this.state.currentTestStepNumber,
                accessCode: this.state.accessCode,
                userEmail: this.state.userEmail,
                clientUrlRoot: this.state.clientUrlRoot,
                apiUrlRoot: this.state.apiUrlRoot,
                serverDisplayName: this.state.serverDisplayName,
                leapFrogDashboardUrl: this.state.leapFrogDashboardUrl,
                testTimer: this.state.testTimer,
                timeLeft: this.timeLeft,
                sampleTestDeadline: this.state.sampleTestDeadline,
                sampleTestDeadlineDate: this.state.sampleTestDeadlineDate,
                deadline: this.state.deadline,
                deadlineDate: this.state.deadlineDate,
                showTimeOutModal: this.state.showTimeOutModal,
                testTimedOut: this.state.testTimedOut,
                testContact: this.state.testContact,
                testFacilityName: this.state.testFacilityName,
                testType: this.state.testType,
                leapFrogHospitalId: this.state.leapFrogHospitalId,
                ttGroup: this.state.ttGroup,
                ttYear: this.state.ttYear
            }}
          >
            {children}
          </UserContext.Provider>
        );
      }
}

export const UserContextConsumer = UserContext.Consumer;
export default UserContextProvider;
